import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const EarringsPage = ({}) => {
  return (
    <CollectionTemplate
      collectionTitle="EARRINGS"
      classExtra="earringscollectionpage"
    >
      <SEO
        title="Earrings"
        keywords={[
          `exclusive`,
          `australian`,
          `earrings`,
          `engagement`,
          `wedding`,
        ]}
        description={`Custom earrings. Shop bridal 'something blue'. Design your dream wedding earrings. Gold and ethical precious gemstones (moissanite, lab diamond, sapphire, morganite)`}
      />
      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEOEarrings" />
        </div> */}
        <div className="SEOText">
          Delicately designed in Western Australia's Swan Valley, our earrings
          blend delightful and inspiring components of yesteryear with sultry,
          modern elements. You'll find plenty of gorgeous blue hues because we
          believe earrings are a great way to include 'something blue' on your
          wedding day. All Rowe Collection designs are fully customisable, from
          gemstones to gold colour and purity. Want something completely unique?
          Contact us to begin your bespoke journey!
        </div>
      </div>
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection">
          all
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/rings">
          rings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/decollete">
          decollete
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/homme">
          homme
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bespoke">
          bespoke
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div id="scroll" className="storewrap">
        <CategoryItems category="earrings" />
      </div>
    </CollectionTemplate>
  )
}

export default EarringsPage
